import React, { useEffect, useState } from "react";
import { Card, Col, Input, Row } from "antd";
import { ScissorOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { useGlobalContext } from "@src/context/globalContext";

import { VideoTrim } from "./videoTrip";
import VideoDurationSetting from "./videoTrimmerSetting";
import "./sceneSettings.less";
import {
  Message01Icon,
  TextSmallcapsIcon,
} from "@src/features/polotno/component/hugeIcons/hugeIcons";
// import { DragDropVerticalIcon, ViewOffSlashIcon } from "hugeicons-react";
const DragDropVerticalIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={28}
    height={28}
    color={"#000000"}
    fill={"none"}
    {...props}
  >
    <path
      d="M8 6H8.00635M8 12H8.00635M8 18H8.00635M15.9937 6H16M15.9937 12H16M15.9937 18H16"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ViewIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={28}
    height={28}
    color={"#000000"}
    fill={"none"}
    {...props}
  >
    <path
      d="M21.544 11.045C21.848 11.4713 22 11.6845 22 12C22 12.3155 21.848 12.5287 21.544 12.955C20.1779 14.8706 16.6892 19 12 19C7.31078 19 3.8221 14.8706 2.45604 12.955C2.15201 12.5287 2 12.3155 2 12C2 11.6845 2.15201 11.4713 2.45604 11.045C3.8221 9.12944 7.31078 5 12 5C16.6892 5 20.1779 9.12944 21.544 11.045Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);
const ViewOffSlashIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={28}
    height={28}
    color={"#000000"}
    fill={"none"}
    {...props}
  >
    <path
      d="M19.439 15.439C20.3636 14.5212 21.0775 13.6091 21.544 12.955C21.848 12.5287 22 12.3155 22 12C22 11.6845 21.848 11.4713 21.544 11.045C20.1779 9.12944 16.6892 5 12 5C11.0922 5 10.2294 5.15476 9.41827 5.41827M6.74742 6.74742C4.73118 8.1072 3.24215 9.94266 2.45604 11.045C2.15201 11.4713 2 11.6845 2 12C2 12.3155 2.15201 12.5287 2.45604 12.955C3.8221 14.8706 7.31078 19 12 19C13.9908 19 15.7651 18.2557 17.2526 17.2526"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.85786 10C9.32783 10.53 9 11.2623 9 12.0711C9 13.6887 10.3113 15 11.9289 15C12.7377 15 13.47 14.6722 14 14.1421"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M3 3L21 21"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ScissorIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={28}
    height={28}
    color={"#000000"}
    fill={"none"}
    {...props}
  >
    <path
      d="M21 6C21 7.65685 19.6569 9 18 9C16.3431 9 15 7.65685 15 6C15 4.34315 16.3431 3 18 3C19.6569 3 21 4.34315 21 6Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M21 18C21 19.6569 19.6569 21 18 21C16.3431 21 15 19.6569 15 18C15 16.3431 16.3431 15 18 15C19.6569 15 21 16.3431 21 18Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M15 8L3 19"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0003 16L11 12"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 5.00011L8.5 10"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Clock01Icon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={28}
    height={28}
    color={"#000000"}
    fill={"none"}
    {...props}
  >
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5" />
    <path
      d="M12 8V12L14 14"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const SceneSettings = observer(({ store }: any) => {
  const [captionWords, setCaptionWords] = useState([]);
  const [textoverlay, setTextOverlay] = useState("");
  const [editableWordIndex, setEditableWordIndex] = useState(null);
  const [editedWord, setEditedWord] = useState("");
  const [newString, setNewString] = useState("");
  const [videoSrc, setVideoSrc] = useState("");
  const [activePageId, setActivePageId] = useState("");
  const [pageDuration, setPageDuration] = useState("");
  const [textOverlayId, setTextOverlayId] = useState("");
  const [elementId, setElementId] = useState("");
  const [element, setElement] = useState<any>({});
  const [sceneElementType, setElementType] = useState("");
  const childs: any = [];

  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    ShowTextOverlay: [showTextOverlay, setShowTextOverlay],
    ActiveIndex: [activeIndex, setActiveIndex],
    ActiveElementId: [activeElementId, setActiveElementId],
    ActiveSearchQueries: [activeSearchQueries, setActiveSearchQueries],
  } = useGlobalContext();
  const handleTextOverlayToggle = (status: boolean) => {
    setShowTextOverlay(status);

    store.pages.map((page: any) => {
      page.children.map((child: any) => {
        if (child.name === "h1-notlinked") {
          const elementID = child.id;
          if (store.getElementById(elementID)) {
            store.getElementById(elementID).set({
              visible: status,
              opacity: status ? 1 : 0,
            });
          }
        }
      });
    });
    // }
  };
  const handleEditClick = (index: any, word: any) => {
    setEditableWordIndex(index);
    setEditedWord(word);
  };
  const handleInputChange = (e: any) => {
    const updatedWords: any = [...captionWords];

    // Update the specific element at the given index
    updatedWords[editableWordIndex || 0] = {
      ...updatedWords[editableWordIndex || 0],
      word: e.target.value,
    };

    setCaptionWords(updatedWords);
    setEditedWord(e.target.value);
    store.activePage.set({
      custom: {
        ...store.activePage.custom,
        captions: [
          {
            text: store.activePage.custom.captions?.[0].text,
            words: updatedWords,
          },
        ],
      },
    });

    setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript };
      const updatedData = [...(prevScript.data ? prevScript.data : prevScript)];
      updatedData[activeIndex || 0] = {
        ...updatedData[activeIndex || 0],
        captions: scriptDetails.data[activeIndex || 0].captions?.[0]?.words
          ? [{ words: updatedWords, text: newString }]
          : [],
      };
      return { ...updatedScript, data: updatedData };
    });
  };
  const handleInputBlur = (index: any) => {
    setEditableWordIndex(null);
  };
  const handleTextOverlayChange = (e: any) => {
    setTextOverlay(e.target.value);
    store.getElementById(textOverlayId).set({
      text: e.target.value,
    });
  };
  useEffect(() => {
    const storeJson = store.toJSON();
    if (
      storeJson.pages.length > 0 &&
      scriptDetails &&
      scriptDetails.data[scriptDetails.data.length - 1].captions
    ) {
      store.setScale(0.25);
      setActivePageId(store.activePage.id);
      store.pages.map((page: any, index: any) => {
        if (page.id === store.activePage.id) {
          setActiveIndex(store.activePage.custom.scriptIndex || index);
          setActiveSearchQueries(
            scriptDetails.data[store.activePage.custom.scriptIndex || index]
              ?.searchQueries ||
              scriptDetails.data[store.activePage.custom.scriptIndex || index]
                ?.images[0].searchQueries
          );
          setCaptionWords(
            scriptDetails.data[store.activePage.custom.scriptIndex || index]
              ?.captions?.[0]?.words
          );
        }
      });

      setActiveElementId(store.activePage?.children[0].id);
      store.activePage?.children?.map((child: any, index: any) => {
        childs.push(child.type);
        if (child.name === "h1-notlinked") {
          setTextOverlay(child.text);
          setTextOverlayId(child.id);
        }
        if (child.type == "video") {
          setElement(child);
          setVideoSrc(child.src);
          setElementId(child.id);
          setElementType("video");
          setActiveElementId(child.id);
        }
      });
      if (!childs.includes("video")) {
        setElement({});
        setVideoSrc("");
        setElementId("");
        setElementType("image");
      }
      setNewString(
        store.activePage?.custom?.captions?.[0]?.words
          .map((item: any) => item.word)
          .join(" ")
      );
    }
  }, [store.activePage, store, scriptDetails]);

  const handleToggleSubtitle = () => {
    if (scriptDetails.showSubtitles) {
      setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript };

        return { ...updatedScript, showSubtitles: false };
      });
      store.pages.map((page: any) => {
        const elementID = "captionText" + page.id;
        page.set({ custom: { ...page.custom, showSubtitles: false } });
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 0,
            backgroundEnabled: false,
          });
        }
      });
    } else {
      setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript };

        return { ...updatedScript, showSubtitles: true };
      });
      store.pages.map((page: any) => {
        const elementID = "captionText" + page.id;
        page.set({ custom: { ...page.custom, showSubtitles: true } });
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 1,
            backgroundEnabled: true,
          });
        }
      });
    }
  };
  const containerStyle: React.CSSProperties = {
    borderBottom: "none",
    borderRadius: "20px 20px 0 0",
    padding: "20px",
    height: "251px",
  };

  const colStyle: React.CSSProperties = {
    backgroundColor: "var(--color-primary-100)",
    borderRadius: "10px",
    height: "100%", // Ensure the columns stretch to the row's height
  };

  return (
    <Card style={containerStyle}>
      <Row gutter={16} style={{ height: "50px", marginTop: "8px" }}>
        <Col span={4} style={{ height: "100%" }}>
          <div
            style={{
              ...colStyle,
              //   padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%", // Ensure the inner div also takes full height
              padding: "5px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Message01Icon color={"var(--color-primary-900)"} />
              <h4 style={{ margin: 0, marginLeft: "8px", fontSize: "12px" }}>
                Captions
              </h4>
            </div>
            {scriptDetails?.showSubtitles ? (
              <ViewOffSlashIcon
                color={"var(--color-primary-900)"}
                style={{ cursor: "pointer" }}
                onClick={handleToggleSubtitle}
              />
            ) : (
              <ViewIcon
                color={"var(--color-primary-900)"}
                style={{ cursor: "pointer" }}
                onClick={handleToggleSubtitle}
              />
            )}
          </div>
        </Col>
        <Col span={20} style={{ height: "100%" }}>
          <div
            style={{
              ...colStyle,
              display: "flex",
              alignItems: "center",
              height: "100%", // Ensure the inner div also takes full height
              padding: "5px",
              overflowX: "auto",
            }}
            className="custom-scrollbar"
          >
            {captionWords?.map((data: any, index: any) => (
              <div
                key={index}
                style={{
                  marginRight: "10px",
                  backgroundColor: "var(--color-primary-200)",
                  borderRadius: "10px",
                  height: "100%",
                  display: "flex", // Use flexbox for centering
                  justifyContent: "center", // Horizontally center the text
                  alignItems: "center", // Vertically center the text
                }}
              >
                {editableWordIndex === index ? (
                  <Input
                    value={editedWord}
                    // onChange={handleInputChange}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={() => handleInputBlur(index)}
                    // style={{ margin: 0, fontSize: "12px", padding: "5px" }}
                    style={{
                      margin: 0,
                      fontSize: "12px",
                      padding: "15px", // Match padding of h2
                      border: "none", // Remove border
                      outline: "none", // Remove outline on focus
                      backgroundColor: "transparent", // Match the background
                      borderRadius: "10px", // Match the rounded corners
                      width: "auto", // Auto width to fit text length
                      minWidth: "50px", // Optional: Minimum width to avoid collapsing
                    }}
                    autoFocus
                    // autoFocus
                  />
                ) : (
                  <h2
                    style={{
                      margin: 0,
                      fontSize: "12px",
                      padding: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEditClick(index, data?.word)} // Allow editing on click
                  >
                    {data?.word}
                  </h2>
                )}
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <Row gutter={16} style={{ height: "50px", marginTop: "8px" }}>
        <Col span={4} style={{ height: "100%" }}>
          <div
            style={{
              ...colStyle,
              //   padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "100%", // Ensure the inner div also takes full height
              padding: "5px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextSmallcapsIcon color={"var(--color-primary-900)"} />
              <h4 style={{ margin: 0, marginLeft: "8px", fontSize: "12px" }}>
                Scene Text
              </h4>
            </div>
            {showTextOverlay ? (
              <ViewOffSlashIcon
                color={"var(--color-primary-900)"}
                style={{ cursor: "pointer" }}
                onClick={() => handleTextOverlayToggle(false)}
              />
            ) : (
              <ViewIcon
                color={"var(--color-primary-900)"}
                style={{ cursor: "pointer" }}
                onClick={() => handleTextOverlayToggle(true)}
              />
            )}
          </div>
        </Col>

        <Col span={20} style={{ height: "100%" }}>
          <div
            style={{
              ...colStyle,
              display: "flex",
              alignItems: "center",
              height: "100%", // Ensure the inner div also takes full height
              padding: "5px",
            }}
          >
            <div
              style={{
                marginRight: "10px",
                backgroundColor: "var(--color-primary-200)",
                borderRadius: "10px",
                height: "100%",
                display: "flex", // Use flexbox for centering
                justifyContent: "center", // Horizontally center the text
                alignItems: "center", // Vertically center the text
              }}
            >
              {/* <h2 style={{ margin: 0, fontSize: "12px", padding: "15px" }}>
                {textoverlay}
              </h2> */}
              <Input
                value={textoverlay}
                onChange={handleTextOverlayChange}
                // onChange={(e) => handleInputChange(e)}
                // style={{ margin: 0, fontSize: "12px", padding: "5px" }}
                style={{
                  margin: 0,
                  fontSize: "12px",
                  padding: "15px", // Match padding of h2
                  border: "none", // Remove border
                  outline: "none", // Remove outline on focus
                  backgroundColor: "transparent", // Match the background
                  borderRadius: "10px", // Match the rounded corners
                  width: "auto", // Auto width to fit text length
                  minWidth: "50px", // Optional: Minimum width to avoid collapsing
                }}
                autoFocus
                // autoFocus
              />
            </div>
          </div>
        </Col>
      </Row>

      {sceneElementType === "video" ? (
        <Row
          gutter={16}
          style={{ height: "50px", marginTop: "8px", overflow: "hidden" }}
        >
          <Col span={4} style={{ height: "100%" }}>
            <div
              style={{
                ...colStyle,
                //   padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "100%", // Ensure the inner div also takes full height
                padding: "5px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <ScissorIcon color={"var(--color-primary-900)"} />
                <h4 style={{ margin: 0, marginLeft: "8px", fontSize: "12px" }}>
                  Trim
                </h4>
              </div>
              {/* <ScissorIcon
       color={"var(--color-primary-900)"}
       style={{ cursor: "pointer" }}
     /> */}
            </div>
          </Col>

          <Col span={20} style={{ height: "100%" }}>
            <div
              style={{
                ...colStyle,
                display: "flex",
                alignItems: "center",
                height: "100%", // Ensure the inner div also takes full height
                padding: "5px",
              }}
            >
              {store.getElementById(elementId) && (
                <div style={{ width: "900px" }}>
                  <VideoTrim
                    store={store}
                    element={store.getElementById(elementId)}
                    activePageId={activePageId}
                    elementId={elementId}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Row gutter={16} style={{ height: "50px", marginTop: "8px" }}>
            <Col span={4} style={{ height: "100%" }}>
              <div
                style={{
                  ...colStyle,

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "100%", // Ensure the inner div also takes full height
                  padding: "5px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Clock01Icon color={"var(--color-primary-900)"} />
                  <h4
                    style={{ margin: 0, marginLeft: "8px", fontSize: "12px" }}
                  >
                    Scene Duration
                  </h4>
                </div>
                {/* <Clock01Icon
        color={"var(--color-primary-900)"}
        style={{ cursor: "pointer" }}
      /> */}
              </div>
            </Col>

            <Col span={20} style={{ height: "100%" }}>
              <div
                style={{
                  ...colStyle,
                  display: "flex",
                  alignItems: "center", // to center element
                  height: "100%", // Ensure the inner div also takes full height
                  padding: "5px",
                }}
              >
                <div style={{ width: "1000px" }}>
                  <VideoDurationSetting store={store} pageId={activePageId} />
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
});

export default SceneSettings;
