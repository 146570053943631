import React, { useEffect, useRef } from "react";
import {
  AbsoluteFill,
  Img,
  OffthreadVideo,
  staticFile,
  useCurrentFrame,
  useVideoConfig,
} from "remotion";
import {
  applyAnimations,
  calculateTrimTimes,
  prefetchAsset,
} from "../../../shared/utils/remotion";

interface Animation {
  delay: number;
  duration: number;
  enabled: boolean;
  type: string;
  name: string;
  data: any;
}

interface Child {
  custom: any;
  id: string;
  type: string;
  text?: string;
  animations: Animation[];
  src?: string;
  x: number;
  y: number;
  width: number;
  height: number;
  rotation: number;
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: string;
  fontStyle?: string;
  textAlign?: string;
  fill?: string;
  cornerRadius?: number;
  borderSize?: number;
  cropX: number;
  cropY: number;
  blurEnabled: boolean;
  blurRadius: number;
  sepiaEnabled: boolean;
  grayscaleEnabled: boolean;
  brightnessEnabled: boolean;
  brightness: number;
  shadowEnabled: boolean;
  shadowBlur: number;
  letterSpacing: number;
  strokeWidth: number;
  stroke: string;
  lineHeight: number;
  cropWidth: number;
  cropHeight: number;
  duration: number;
  startTime: number;
  endTime: number;
}

interface PageData {
  id: string;
  children: Child[];
}

interface SinglePageProps {
  pageHtml: any; // HTML string for the page content
  width: number;
  height: number;
  pageData: PageData; // Adjusted to pass JSON data
  isDownload: boolean;
  aiAvatar:boolean
}

export const SinglePage: React.FC<SinglePageProps> = React.memo(
  ({ pageHtml, width, height, pageData, isDownload, aiAvatar }: SinglePageProps) => {
    const frame = useCurrentFrame();
    const { fps, durationInFrames } = useVideoConfig();
    const pageRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      applyAnimations(
        pageRef,
        pageData,
        frame,
        fps,
        durationInFrames,
        width,
        height
      );
    }, [frame, pageData]);

    useEffect(() => {
      async function prefetch() {
        await Promise.all(
          pageData?.children.map(async (child: any) => {
            // Prefetch for all children in parallel
            child.type === "video" || (child.type === "image" && child.src)
              ? prefetchAsset(
                  child.src,
                  child.type === "image" ? "image/png" : "video/mp4"
                )
              : Promise.resolve();
          })
        );
      }
      prefetch();
    }, []);

    const scaleFactor = isDownload
      ? 1
      : Math.min(width / 1 / width, height / 1 / height);

    // console.log(isDownload);
    return (
      <AbsoluteFill>
        <AbsoluteFill>
          {pageData?.children.map((child, index) => {
            if (child.type === "video" && child.src) {
         
              const cropStyle: React.CSSProperties = {
                position: "absolute",
                overflow: "hidden",
                left: `${child.x * scaleFactor}px`,
                top: `${child.y * scaleFactor}px`,
                width: isDownload
                  ? `${child.width}px`
                  : `${child.width * scaleFactor}px`,
                height: isDownload
                  ? `${child.height}px`
                  : `${child.height * scaleFactor}px`,
                transform: `rotate(${child.rotation}deg)`,
              };

              const trimedDuration = calculateTrimTimes(
                child.duration || child?.custom?.duration || 20000,
                child.startTime || child?.custom?.startTime || 0,
                child.endTime || child?.custom?.endTime || 1,
                fps
              );
          
              const videoStyle: React.CSSProperties = {
                position: 'absolute',
                width: `${1 / child.cropWidth * 100}%`, // Scale video based on crop width
                height: `${1 / child.cropHeight * 100}%`, // Scale video based on crop height
                objectFit: 'cover',
                // objectPosition: `${child.cropX * 100}% ${child.cropY * 100}%`, // Adjust based on crop x and y
                transform: `translate(-${child.cropX * 100}%, -${child.cropY * 100}%)`,
              };

             

              return (
                <div key={child.id} style={cropStyle}>
                  <OffthreadVideo
                    src={child.src}
                    style={videoStyle}
                    startFrom={trimedDuration.startTime}
                    // endAt={trimedDuration.endTime}
                    // muted={aiAvatar?false:true}
                    muted={child.src.includes('stream.mux') ? false : true}
                  />
                </div>
              );
            } else if (child.type === "image" && child.src) {
              const cropStyle: React.CSSProperties = {
                position: "absolute",
                overflow: "hidden",
                left: isDownload
                  ? `${child.x}px`
                  : `${child.x * scaleFactor}px`,
                top: isDownload ? `${child.y}px` : `${child.y * scaleFactor}px`,
                width: isDownload
                  ? `${child.width}px`
                  : `${child.width * scaleFactor}px`,
                height: isDownload
                  ? `${child.height}px`
                  : `${child.height * scaleFactor}px`,
                transform: `rotate(${child.rotation}deg)`,
              };

              // Define the image cropping style based on the crop properties from the JSON
              const imageStyle: React.CSSProperties = {
                position: "absolute",
                width: `${(1 / child.cropWidth) * 100}%`, // Scale the image based on crop width
                height: `${(1 / child.cropHeight) * 100}%`, // Scale the image based on crop height
                objectFit: "cover",
                // objectPosition: `${child.cropX * 100}% ${child.cropY * 100}%`, // Adjust the part of the image to be shown
                transform: `translate(-${child.cropX * 100}%, -${child.cropY * 100}%)`,
              };

              return (
                <div key={child.id} style={cropStyle}>
                  <Img src={child.src} style={imageStyle} />
                </div>
              );
            }
            return null;
          })}
        </AbsoluteFill>
        <AbsoluteFill>
          <div
            ref={pageRef}
            style={{
              position: "absolute",

              transform: isDownload ? "none" : `scale(${scaleFactor})`,
              // transform: isDownload
              //   ? 'none'
              //   : `perspective(${perspectiveDistance}px) rotateX(${rotationX}deg) rotateY(${rotationY}deg) translateZ(${translateZ}px) scale(${scaleFactor})`,
              // transformOrigin: 'center center',

              transformOrigin: "top left",

              width: `${width}px`,
              height: `${height}px`,
            }}
            dangerouslySetInnerHTML={{ __html: pageHtml }}
          />
        </AbsoluteFill>
      </AbsoluteFill>
    );
  }
);

SinglePage.displayName = "SinglePage";
