import React, { useEffect, useRef, useState } from 'react';
import { Slider, Switch, Button, Popover, Row, Col, InputNumber } from 'antd';
import type { SliderSingleProps } from 'antd/es/slider'; // Adjusting to the correct path for type
import { observer } from "mobx-react-lite";

// import {getVideoDurationInSeconds } from 'get-video-duration'
// import { Button, Popover } from 'antd';
const VideoDurationSetting =  ({store, pageId}:any) => {
  const [pageDurtation, setPageDuration] = useState(0)

  const formatter: NonNullable<SliderSingleProps['tooltip']>['formatter'] = (value) => `${value} sec`;

 function normalize(value:number, min:number, max:number) {
  const clampedValue = Math.max(min, Math.min(value, max)); // Clamping the value
  return (clampedValue - min) / (max - min);
}

function denormalize(normalizedValue:any, min:number, max:number) {
  return (normalizedValue * (max - min)) + min;
}

function secondsToMilliseconds(seconds:number) {
  return seconds * 1000;
}

  const onChange = (value:any) => {

    const durationAfterTrimming = secondsToMilliseconds(value)
    setPageDuration(value)
      const page=store.getElementById(pageId)
      page.set({
        duration:durationAfterTrimming
      })

  };
  useEffect(()=>{
    console.log(pageId)
    console.log(store.pages.length)

    if(store.pages.length>0 && store.getElementById(pageId)){
      const page = store.getElementById(pageId)
      setPageDuration(page.duration/1000)
    }
  },[store, pageId])

  return (
    <>
        {/* <Slider step={0.1}  min={0} max={videoLength} range defaultValue={[savedStartTime || 0, savedEndTime || videoLength|| 0]}  disabled={disabled} onChange={onChange} tooltip={{ formatter }} />
        <video src={videoUrl} ref={videoEl} onLoadedMetadata={handleLoadedMetadata} style={{display:'none'}}/> */}
   <Row>
      <Col span={18}>
        <Slider
          min={1}
          max={30}
          step={0.1}
          onChange={onChange}
          value={typeof pageDurtation === 'number' ? pageDurtation : 0}
          tooltip={{ formatter }}
        />
      </Col>
      <Col span={4}>
        <InputNumber
          min={1}
          max={30}
          style={{ margin: '0 16px' }}
          value={pageDurtation }
          onChange={onChange}
          readOnly
        />

      </Col>
    </Row>
    </>
  );
};

export default VideoDurationSetting;