import { observer } from "mobx-react-lite";
import {
  getVideoDuration,
  getVideoObjectPreview,
  getVideoPreview,
  getVideoSize,
} from "polotno/utils/video";
import React, { useEffect, useState } from "react";
import { Layout, InputNumber, Row, Col } from "antd";
import { useGlobalContext } from "@src/context/globalContext";
const { Header } = Layout;
function secondsToMilliseconds(seconds: number) {
  return seconds * 1000;
}
export const VideoTrim = observer(
  ({ store, element, activePageId, elementId }: any) => {
    const groupRef = React.useRef<HTMLDivElement>(null);
    const [previews, setPreviews] = React.useState<Array<string>>([]);
    const [videoDuration, setVideoDuration] = useState(0)
    React.useEffect(() => {
      setPreviews([]);
      handleOpen();
      console.log(element.startTime);
      console.log(element.endTime);
    }, [element.src, activePageId, elementId]);

    const handleOpen = async () => {
      if (!element.src) return;
      // if (previews.length) return;

      const { width, height } = await getVideoSize(element.src);
      const ratio = width / height;
      const { src } = element;
      const offsetHeight = groupRef?.current?.offsetHeight;
      const offsetWidth = groupRef?.current?.offsetWidth;
      const videoWidth = offsetHeight || 1 * ratio;
      const numberOfPreviews = Math.ceil(offsetWidth || 1 / videoWidth);
      const duration = await getVideoDuration(src);
      setVideoDuration(duration)
      const video = document.createElement("video");
      video.crossOrigin = "anonymous";
      video.src = src;

      const canvas = document.createElement("canvas");
      canvas.width = 480; // Adjust as needed

      try {
        await new Promise<void>((resolve, reject) => {
          video.addEventListener("loadeddata", () => {
            const ratio = video.videoWidth / video.videoHeight;
            canvas.height = 480 / ratio;
            resolve();
          });
          video.addEventListener("error", reject);
        });

        setPreviews([]);
        const preview = await getVideoPreview(src);
        const previewsArray = Array(numberOfPreviews).fill(preview);
        setPreviews(previewsArray);
        // for (let i = 0; i < numberOfPreviews; i++) {
        //   const captureTime = (i * duration) / numberOfPreviews;
        //   // console.log(video, canvas, captureTime)
        //   // const preview = await getVideoObjectPreview(video, canvas, captureTime) as string;
        // // console.log(video)
        //   const  preview = await getVideoPreview(
        //     src
        //   );

        //   // console.log(preview)
        //   setPreviews((prevs) => prevs.concat(preview));
        // }
      } catch (err) {
        console.error("Error generating previews:", err);
      }
    };

    const handleStartDrag = async (e: any, type: any) => {
      e.preventDefault();
      const handleMove = async (e: any) => {
        e.preventDefault();
        const { clientX } = e;
        // const { left, width }:any = groupRef?.current?.getBoundingClientRect();
        const rect = groupRef?.current?.getBoundingClientRect();
        const left = rect?.left ?? 0; // Fallback to 0 if undefined
        const width = rect?.width ?? 0; // Fallback to 0 if undefined
        const { src } = element;
        const time = (clientX - left) / width;
        if (type === "start") {
          await element.set({
            startTime: Math.min(element.endTime, Math.max(0, time)),
          });
        } else if (type === "end") {
          await element.set({
            endTime: Math.min(1, Math.max(element.startTime, time)),
          });
        }
        const updatedElement = store.getElementById(elementId);
        const pageDurationInsec = parseFloat(
          (
            (updatedElement.duration *
              (updatedElement.endTime - updatedElement.startTime)) /
            1000
          ).toFixed(2)
        );

        const durationAfterTrimming = secondsToMilliseconds(pageDurationInsec);

        const page = store.getElementById(activePageId);
        console.log(durationAfterTrimming);
        page.set({
          duration: durationAfterTrimming,
        });

        // const duration = await getVideoDuration(src);
        // const ratio = (clientX - offsetLeft) / offsetWidth;
        // const newDuration = Math.round(duration * ratio);
        // element.duration = newDuration;
      };
      window.addEventListener("mousemove", handleMove);
      window.addEventListener("mouseup", () => {
        window.removeEventListener("mousemove", handleMove);
      });
    };
    function denormalize(normalizedValue:any, min:number, max:number) {
      return (normalizedValue * (max - min)) + min;
    }
    // useEffect(()=>{
    //     handleOpen()
    // },[])
    return (
      <Header
        style={{
          boxShadow: "none",
          backgroundColor: "transparent",
          // width: 'calc(100vw - 300px)',
          display: "flex",
          alignItems: "center",
        }}
      >
        <p
        // style={{
        //   width: '100px',
        // }}
        // value={parseFloat(
        //   ((element.duration * (element.endTime - element.startTime)) / 1000).toFixed(2)
        // )}
        // step={0.1}
        // min={0}
        // readOnly
        >
          {parseFloat(
            (
              (element.duration * (element.endTime - element.startTime)) /
              1000
            ).toFixed(2)
          )}
        </p>
        <div
          style={{
            width: "calc(100vw - 420px)",
            height: "30px",
            display: "flex",
            position: "relative",
            overflow: "hidden",
            marginLeft: "10px",
          }}
          ref={groupRef}
        >
          {previews.map((preview, index) => (
            <img
              key={index}
              src={preview}
              style={{
                width: "auto",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ))}

          {/* Start backdrop */}
          <div
            style={{
              position: "absolute",
              top: "0",
              left: 0,
              width: `${element.startTime * 100}%`,
              height: "30px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          ></div>
          {/* End backdrop */}

          <div
            style={{
              position: "absolute",
              top: "0",
              right: 0,
              width: `${100 - element.endTime * 100}%`,
              height: "30px",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          ></div>

          <div
            style={{
              position: "absolute",
              top: "0",
              left: `${element.startTime * 100}%`,
              width: "10px",
              height: "30px",
              borderRadius: "0px",
              backgroundColor: "rgba(0, 161, 255, 0.9)",
              cursor: "ew-resize",
            }}
            onMouseDown={(e) => {
              handleStartDrag(e, "start");
            }}
          > <p
          style={{
            position:'relative',
            top:'-10px',
            fontSize:'16px',
            color:'white'
          }}  
          >
            {`${denormalize(element.startTime, 0, videoDuration).toFixed(1)}sec`} 
            </p></div>

          <div
            style={{
              position: "absolute",
              top: "0",
              right: `${100 - element.endTime * 100}%`,
              width: "10px",
              height: "30px",
              borderRadius: "0px",
              backgroundColor: "rgba(0, 161, 255, 0.9)",
              cursor: "ew-resize",
            }}
            onMouseDown={(e) => {
              handleStartDrag(e, "end");
            }}
          >
            <p
          style={{
            position:'relative',
            top:'-10px',
            fontSize:'16px',
            color:'white'
          }}  
          >
            {/* {element.endTime.toFixed(2)} sec */}
            {`${denormalize(element.endTime, 0, videoDuration).toFixed(1)}sec`} 


            </p>
          </div>
        </div>
     
      </Header>
    );
  }
);
