/* eslint-disable react/prop-types */
import {
  CameraOutlined,
  DownloadOutlined,
  FileGifOutlined,
  FileImageOutlined,
  LoadingOutlined,
  SaveOutlined,
} from "@ant-design/icons";

import { NODE_BACKEND_URL, IS_FREE_PLATFORM } from "../../../../config";
import { getDomainID, getToken, qaBackendApiInstance } from "../../../../config/config";
import { useGlobalContext } from "../../../../context/globalContext";
import { burstImageTransitionFunctions } from "../../../../data/transitions";
import { trackEvent } from "../../../../lib/mixpanel";
import {
  millisecondsToFrames,
  prefetchAsset,
} from "../../../../shared/utils/remotion";
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  Progress,
  Result,
  Select,
  Spin,
  message,
} from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { BulkCreateButton } from "../bulkCreateContainer";
import { PreviewButton } from "../previewContainer";
import { ScriptButton } from "../script/newScriptModal";
import {
  extractPagesFromHtml,
  addIdsToHtmlFromJson,
  addPreloadAutoToHTMLArray,
} from "../../../../shared/utils/remotion/htmlProcessing";
import {
  creditsDeduct,
  gettyAssetTrack,
  GettyQualityImage,
  GettyVideoDownloadSizes,
  getUserDetails,
} from "../../../../shared/utils/VideoGeneration/helperFunctions/mediaSearch";
import {
  generateUniqueId,
  handlePutImage,
} from "../../../../shared/utils/core";
import { preloadVideo } from "../../../../shared/utils/preloadVideo";
import { loadVideoMetadata } from "../../../../shared/utils/prefetchVideos";
import io from "socket.io-client";
import { useDeductCredits } from "@src/api/useDeductCredits";
import { AnimationDropdown } from "@src/features/animation/animationSelect";
import extractIdFromUrl from "@src/shared/utils/exractIdFromGettyUrl";
import { downloadFilesAsZip } from "@src/shared/utils/saveAssetsZip/saveAssetsZip";
import useSaveLog from "@src/api/logs/useSaveLogs";
import { aiTextToSpeech } from "@src/shared/utils/VideoGeneration/audioGeneration";
import { openAIVoicesList } from "@src/data/music";
// import { preloadAudio, preloadVideo } from '@remotion/preload';

// import { GettyVideoDownloadSizes } from '@src/shared/utils/VideoGeneration/helperFunctions/mediaSearch'
console.log(NODE_BACKEND_URL);
// const backendUrl = NODE_BACKEND_URL
// const socket = io('http://localhost:3100/')
const socket = io("https://videos-node-be.quickads.ai/");
interface DownloadProps {
  store: any; // Ideally, replace `any` with a more specific type
  open: boolean;
  setOpen: any;
  bulkCreatePropsArray: any;
  setBulkCreatePropsArray: any;
  previewOpen: boolean;
  setPreviewOpen: any;
  setProgressBarPercentage: any;
}

export const CustomActionControlContainer: React.FC<DownloadProps> = ({
  store,
  open,
  setOpen,
  bulkCreatePropsArray,
  setBulkCreatePropsArray,
  previewOpen,
  setPreviewOpen,
  setProgressBarPercentage,
}) => {
  const {
    UserDetail: [userDetails],
    UpdatedHeadings: [heading],
    TransitionAnimation: [transitionAnimation],
    ScriptDetails: [scriptDetails],
    VideoStyle: [videoStyle],
    VideoMediaType: [videoMediaType],
    CurrentUserData: [currentUserData],

  } = useGlobalContext();
  const projectID = location.pathname.split('/').pop()

  const User = JSON.parse(localStorage.getItem("userData") as string);

  // update and save as new button loading animation
  const [isUpdateLoading, setUpdateLoading] = useState<boolean>(false);
  const [isSaveAsNewLoading, setSaveAsNewLoading] = useState<boolean>(false);
  const [renderTime, setrenderTime] = useState(5);
  const [downloadModal, setDownloadModal] = useState(false);
  const [isdownloadLink, setIsDownloadLink] = useState(false);
  const [renderprogress, setRenderProgress] = useState(0);
  const [renderBegins, setRenderBegins] = useState(false);
  const [downloadPercentage, setDownloadPercentage] = useState(0);
  const [isDownloadingIndividual, setIsDownloadingIndividual] = useState(false)
  // menu states
  const [saveMenuVisible, setSaveMenuVisible] = useState(false);

  // save As new
  const [creationSuccess, setCreationSuccess] = useState(false);
  const [newProjectID, setNewProjectID] = useState("");
  const [remotionDownloadUrl, setDownloadUrl] = useState("");
  // downloding mp4
  const [isMp4Downloding, setIsMp4Downloading] = useState<boolean>(false);
  const [generateuniqueRoomId, setGenerateUniqueRoomId] = useState("");
  const [isRenderAndSaving, setIsRenderAndSaving] = useState<boolean>(false);
  // const {data: responseData} = useDeductCredits('video_credits')
  const { mutate:saveLogs} = useSaveLog();

  const assetIds: any[] = [];

  useEffect(() => {
    setRenderProgress(0);
    setDownloadPercentage(0);
    const roomId = generateUniqueId(); // Generate or get a unique room ID
    setGenerateUniqueRoomId(roomId);
    socket.emit("joinRoom", roomId);
    socket.on("progress", (data: { percentage: number }) => {
      console.log(data.percentage);
      setRenderProgress(data.percentage);
      // setRenderProgress(data.percentage)
    });

    return () => {
      socket.off("progress");
    };
  }, []);
  async function deductCredits() {
    try {
      const response = await creditsDeduct("video_credits", 1);
    } catch (error) {
      message.error("Something went wrong!");
    }
  }

  // for handling the result screen
  const handleGoToNewProject = () => {
    window.location.href = `/${newProjectID}`;
  };

  const handleStay = () => {
    setCreationSuccess(false); // Optionally reset or handle as needed
  };

  // for handling the state of save menu
  const handleMenuClick = (e: {
    domEvent: { stopPropagation: () => void };
  }) => {
    e.domEvent.stopPropagation(); // Stop the menu from closing
  };

  // Download image
  const handleDownloadImage = async () => {
    const savePromises = store.pages.map((page: { id: string }) =>
      store.saveAsImage({ pageId: page.id })
    );

    try {
      await Promise.all(savePromises);
      console.log("All pages saved as images.");
    } catch (error) {
      console.error("Error saving one or more pages as images:", error);
    }
  };

  // Download gif
  const handleDownloadGif = async () => {
    trackEvent("Free Video Ad Downloaded");
    await store.saveAsGIF();
  };
  function smoothScrollTo(
    element: HTMLElement,
    target: number,
    duration: number
  ): Promise<void> {
    return new Promise((resolve) => {
      const start = element.scrollTop;
      const change = target - start;
      const startTime = performance.now();

      function animateScroll(currentTime: number) {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);
        const easeInOutQuad = (t: number) =>
          t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t; // Easing function
        element.scrollTop = start + change * easeInOutQuad(progress);

        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        } else {
          resolve();
        }
      }

      requestAnimationFrame(animateScroll);
    });
  }

  const waterMarkedAsset: { id: any; src: any }[] = [];

  const updateVideos = async () => {
    const currentDate = new Date().toISOString().split('T')[0];

    for (const page of store.pages) {
      for (const child of page.children) {
        if (child.type === "video") {
          const element = store.getElementById(child.id);
          const obj = { id: child.id, src: child.src };
          waterMarkedAsset.push(obj);
          const assetId = extractIdFromUrl(child.src);
          if (element && assetId) {
            if (child.custom) {
              try {
                const qualityUrl = await GettyVideoDownloadSizes(
                  assetId 
                );
                if (qualityUrl) {
                  element.set({ src: qualityUrl });
                  assetIds.push({
                    asset_id: assetId,
                    quantity: 1,
                    usage_date: currentDate,
                  });
                }
               
              } catch (error) {
                console.error(
                  `Failed to get video quality URL for id ${child.custom.gettyId}: `,
                  error
                );
              }
            }
          }
        } else if (child.type === "image") {
          const element = store.getElementById(child.id);
          console.log(child.src);
          const assetId = extractIdFromUrl(child.src);
          if (element && assetId) {
            if (child.custom) {
              try {
                const qualityUrl = await GettyQualityImage(assetId || "");

                if (qualityUrl) {
                  element.set({ src: qualityUrl });
                  assetIds.push({
                    asset_id: assetId,
                    quantity: 1,
                    usage_date: currentDate,
                  });
                }
               
              } catch (error) {
                console.error(
                  `Failed to get video quality URL for id ${child.custom.gettyId}: `,
                  error
                );
              }
            }
          }
        }
      }
    }
  };

  const handleDownloadMp4 = async (downloadVideo: boolean) => {
    // if(videoMediaType !== 'AiImages' && videoMediaType !== 'Dalle'){
    //   message.warning("Downloads have a watermark due to Getty licensing issues, which we are currently resolving. Please try again later.")
    //   return
    // }

    if (!downloadVideo) {
      setIsRenderAndSaving(true);
    } else {
      if(userDetails?.purpose?.noVideo){
        setRenderBegins(false);
      }else{
        setRenderBegins(true);

      }
      setIsMp4Downloading(true);
    }
    const getCredits = await getUserDetails();

    if (getCredits == -1) {
      message.warning("Something went wrong!");
      setIsMp4Downloading(false);
      setIsRenderAndSaving(false);
      return;
    }

    if (getCredits?.video_limit < 1) {
      message.warning(
        "Video credits have been exhausted. Please upgrade the plan to download the video."
      );
      setIsMp4Downloading(false);
      setIsRenderAndSaving(false);

      return;
    }
    // console.log(deductResponse)
    // console.log(responseData)
    if(userDetails.purpose?.noVideo){
      console.log(scriptDetails)
      console.log(scriptDetails.data.map((data: { voiceOver: string }) => data.voiceOver).join(' '))
      const text=scriptDetails.data.map((data: { voiceOver: string }) => data.voiceOver).join(' ')
      const domainId = getDomainID();
      const token = getToken();
      const isOpenAIVoice = openAIVoicesList.includes(scriptDetails?.data[0]?.name)
      const audioUrl = await aiTextToSpeech(
        text,
        scriptDetails?.data[0]?.name,
        domainId,
        scriptDetails?.data[0]?.name,
        !isOpenAIVoice,
        token,
        '',
        '',
        50,
        75,
      )
      const link = document.createElement('a'); // Create a new anchor element
    link.href = audioUrl;                     // Set the audio file URL
    link.download = 'audioUrl';          // Set the file name for download
    document.body.appendChild(link);           // Append the link to the DOM
    link.click();                              // Programmatically click the link to start download
    document.body.removeChild(link);           // Remove the link after download starts
    setIsMp4Downloading(false);
     
    return
    }
    if (renderprogress > 1 || downloadPercentage > 1 || isMp4Downloding) {
      setRenderBegins(true);
    } else {
      if (downloadVideo) {
        updateProject();
      }

      if (waterMarkedAsset.length < 1) {
        await updateVideos();
      }
      saveLogs(
        {
          module: `Video Download`,
          message: `Download Started`,
          project_id: Number(projectID) || 0,
          type: "Video",
        }
      
      )
      trackEvent("Video", {
        name: currentUserData?.name,
        email: currentUserData?.email,
        message: `Download Started for project id: ${projectID}`,
        type: "INFO",
        submodule: "Video_Download_Started",
      });
      // setIsMp4Downloading(true);
      // await store.saveAsGIF()
      // this gets the json from polotno
      const jsonData = await store.toJSON();
      // if the video style is burst we make an array of random transition functions
      const burstImagesTransitionAnimationArray = [];

      if (videoStyle === "Burst") {
        for (let i = 0; i < scriptDetails.data.length; i++) {
          const innerArray = [];

          for (let j = 0; j < scriptDetails.data[i].images.length; j++) {
            const randomFunction =
              burstImageTransitionFunctions[
                Math.floor(Math.random() * burstImageTransitionFunctions.length)
              ];
            // innerArray.push(randomFunction.value)
            innerArray.push(transitionAnimation[0].animation);
          }
          burstImagesTransitionAnimationArray.push(innerArray);
        }
      }

      // prefetching all the video or image url in browser to make sure that the video playing is not glitchy
      const promises = await Promise.all(
        jsonData.pages.map(async (page: any) => {
          await prefetchAsset(page?.custom?.sounds[0]?.url);
          // Prefetch for all children in parallel
          // loadVideoMetadata(child.src)
          await Promise.all(
            page.children.map(async (child: any) =>
              (child.type === "video" || child.type === "image") && child.src
                ? child.type === "video" && preloadVideo(child.src)
                : Promise.resolve()
            )
          );
        })
      );

      /* this is done so that the caption text on polotno does not show up in the downloaded video.
  the text shown on polotno when captions is enabled is only shown visually for the user*/
      store.pages.map((page: any) => {
        const elementID = "captionText" + page.id;
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 0,
          });
        }
      });
      // getting the html code
      const result = await store.toHTML();
      // converting the html code into different pages so it reflects each page in json
      const htmlCode = extractPagesFromHtml(result);
      // adding tags into that htmlCode array so that we can show animations
      const htmlCodeWithTags = await addIdsToHtmlFromJson(htmlCode, jsonData);

      const data = htmlCodeWithTags;
      // console.log(data)
      // const modifieddata = addPreloadAutoToHTMLArray(data)
      // console.log(modifieddata)
      // converting the duration thats in store which is in miliseconds into duration that remotion can understand
      const duration = await millisecondsToFrames(store.duration, 30);

      // getting transition duration from the global context
      const transitionDurationInFrames = await transitionAnimation[0].duration;

      // getting the transition function from the global context
      const transitionAnimationProp = await transitionAnimation[0].animation;

      const isDownload = false;
      // prefetching the background music so it plays without lag
      // await prefetchAsset(scriptDetails?.backgroundMusic?.url)
      if (scriptDetails?.showSubtitles) {
        store.pages.map((page: any) => {
          const elementID = "captionText" + page.id;
          if (store.getElementById(elementID)) {
            store.getElementById(elementID).set({
              opacity: 1,
            });
          }
        });
      }

      axios
        .post(`${NODE_BACKEND_URL}api/v1/remotion/render`, {
          data,
          jsonData,
          duration,
          transitionDurationInFrames,
          transitionAnimationProp,
          isDownload,
          scriptDetails,
          burstImagesTransitionAnimationArray,
          videoStyle,
          isFree: true,
          generateuniqueRoomId,
          userDetails:userDetails.purpose?.aiAvatar
        })
        .then((response) => {
          const videoUrl = response.data; // Assuming response.data contains the URL of the video
          store.set({
            custom: { remotionUrl: videoUrl, lastRendered: Date.now() },
          });
          if (downloadVideo) {
            message.success("Rendered Successfully", 5);
            setRenderProgress(100);
            setBackToWaterMark(waterMarkedAsset);

            updateProject();
            setDownloadUrl(videoUrl);
            setIsMp4Downloading(false);
            saveLogs(
              {
                module: `Video Download`,
                message: `Download Successfull`,
                project_id: Number(projectID) || 0,
                type: "info",
              }
            )
            trackEvent("Video", {
              name: currentUserData?.name,
              email: currentUserData?.email,
              message: `Download Successfull for project id: ${projectID}`,
              type: "INFO",
              submodule: "Video_Download_Success",
            });
            try {
              if(!userDetails.purpose?.aiAvatar){
                gettyAssetTrack(JSON.stringify(assetIds), 1)

              }
            } catch (error) {
              console.log(error)
            }
          } else {
            setBackToWaterMark(waterMarkedAsset);

            updateProject();

            setIsRenderAndSaving(false);
            message.success(
              "Rendered successfully. You can download the video from the dashboard at any time."
            );
            saveLogs(
              {
                module: `Video Download`,
                message: `Render Successfull`,
                project_id: Number(projectID) || 0,
                type: "info",
              }
            )
            trackEvent("Video", {
              name: currentUserData?.name,
              email: currentUserData?.email,
              message: `Rendered Successfull for project id: ${projectID}`,
              type: "INFO",
              submodule: "Video_Render_Successfull",
            });

            try {
              if(!userDetails.purpose?.aiAvatar){
                gettyAssetTrack(JSON.stringify(assetIds), 1)

              }
             
            } catch (error) {
              console.log(error)
              saveLogs(
                {
                  module: `Video Download`,
                  message: `Render Failed`,
                  project_id: Number(projectID) || 0,
                  type: "Video",
                }
              )
              trackEvent("Video", {
                name: currentUserData?.name,
                email: currentUserData?.email,
                message: `Rendered failed for project id: ${projectID}`,
                type: "ERROR",
                submodule: "Video_Render_Failed",
              });
            }
          }
          // Fetch the video as a Blob

          // downloadFile(response.data);
          // fetch(videoUrl)
          //   .then((response) => response.blob())
          //   .then((blob) => {
          //     // downloadFile(response.data);
          //   })
          //   .catch((error) => {
          //     setIsMp4Downloading(false);
          //     setDownloadModal(false);
          //     setrenderTime(0);

          //     setDownloadUrl(videoUrl);
          //     setIsDownloadLink(true);
          //   });
        })
        .catch((error) => {
          setIsRenderAndSaving(false);

          console.error("Error rendering the video: ", error);
          setIsMp4Downloading(false);
          setrenderTime(0);
          message.error(
            "Render failed, please try again by loading all the pages.",
            5
          );
          saveLogs(
            {
              module: `Video Download`,
              message: `Render Failed`,
              project_id: Number(projectID) || 0,
              type: "Video",
            }
          )
          
          trackEvent("Video", {
            name: currentUserData?.name,
            email: currentUserData?.email,
            message: `Rendered failed for project id: ${projectID}`,
            type: "ERROR",
            submodule: "Video_Render_Failed",
          });
        
          setDownloadModal(false);
          setRenderProgress(0);
          setDownloadPercentage(0);
          setBackToWaterMark(waterMarkedAsset);
          error();
        });

      // setBackToWaterMark(waterMarkedAsset)
    }
  };

  const renderAndSave = async () => {
    await handleDownloadMp4(false);
    updateProject();
  };
  function setBackToWaterMark(waterMarkedAsset: any) {
    for (const page of store.pages) {
      for (const child of page.children) {
        if (child.type === "video") {
          const element = store.getElementById(child.id);
          for (const obj of waterMarkedAsset) {
            if (obj.id === child.id) {
              element.set({ src: obj.src });
            }
          }
        }
      }
    }
    waterMarkedAsset = [];
  }

  const error = () => {
    Modal.error({
      title: "Render Failed",
      content: "Please try again after loading all the pages.",
    });
  };

  // update project api call put call
  async function updateProject(): Promise<any> {
    setUpdateLoading(true);
    try {
      const payload = userDetails;
      payload.json_body = store.toJSON();

      payload.script = scriptDetails;
      payload.first_render = false;
      const domainID = getDomainID() || "";
      const response = await qaBackendApiInstance.put(
        `/smp/${userDetails.id}?domain_id=${encodeURIComponent(domainID)}`,
        { ...payload }
      );
      if (response.status === 200) {
        message.success("Project updated successfully", 5);
        setUpdateLoading(false);
        // Handle successful upload, e.g., update state, display message, etc.
      } else {
        message.error("Project could not be updated", 5);
        // Handle non-200 responses
      }
    } catch (error) {
      console.error("Error updating project:", error);
      message.error("Project could not be updated.");
    }
  }

  // save as new api call post call
  const createNewProject = async (userDetails: any): Promise<any> => {
    trackEvent("Free Video Ad Generated");

    setSaveAsNewLoading(true);
    try {
      const payload = {
        ...userDetails,
        heading: heading.heading,
        subHeading: heading.subHeading,
        third_heading: heading.thirdHeading,
      };
      payload.json_body = store.toJSON();
      delete payload.id;

      const response = await qaBackendApiInstance.post(
        `/smp/save-new-ad/${userDetails.id}?domain_id=${getDomainID()}`,
        payload
      );
      const blob = await store.toBlob({ mimeType: "image/jpg" });
      const file = new File([blob], "preview.jpg");
      const formData = new FormData();
      formData.append("file", file);

      await qaBackendApiInstance.post(
        `/smp/save-ad-image/${
          response.data.project_id
        }?domain_id=${getDomainID()}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // setting the new project id after creating a new project
      setNewProjectID(response.data.project_id);
      setCreationSuccess(true); // Indicate success to show the Result component
      setSaveAsNewLoading(false);
      setSaveMenuVisible(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("Upload failed.");
      setSaveAsNewLoading(false);
    }
  };
  const handleCloseDownload = () => {
    setIsDownloadLink(false);
  };

  const handleDownloadAssets = async () => {

    setIsDownloadingIndividual(true)
    const getCredits = await getUserDetails();

    if (getCredits == -1) {
      message.warning("Something went wrong!");
      setIsDownloadingIndividual(false)
      return;
    }

    if (getCredits?.video_limit < 1) {
      message.warning(
        "Video credits have been exhausted. Please upgrade the plan to download the video."
      );
      setIsDownloadingIndividual(false)
      return;
    }
  
    await updateVideos();

    const jsonData = store.toJSON();
    const audioFiles: any = [];
    const imageFiles: any = [];
    const videoFiles: any = [];
    const backgroundMusic = scriptDetails?.backgroundMusic?.url;
  
    let imageCounter = 0;
    let videoCounter = 0;

    for (let pageIndex = 0; pageIndex < jsonData.pages.length; pageIndex++) {
      const page = jsonData.pages[pageIndex];

      // Add audio file from custom sounds (if available)
      const audioUrl = page?.custom?.sounds?.[0]?.url;
      if (audioUrl) {
        audioFiles.push({
          name: `Ai voice scene ${pageIndex + 1}`,
          url: audioUrl,
        });
      }

      // Iterate over the children in each page
      for (
        let childIndex = 0;
        childIndex < page.children.length;
        childIndex++
      ) {
        const child = page.children[childIndex];

        if (child.type === "image") {
          imageFiles.push({
            name: `Image ${imageCounter + 1}`,
            url: child.src,
          });
          imageCounter++; // Increment global image counter
        }

        if (child.type === "video") {
          videoFiles.push({
            name: `Video ${videoCounter + 1}`,
            url: child.src,
          });
          videoCounter++; // Increment global video counter
        }
      }
    }
   
    audioFiles.push({ name: "Background music", url: backgroundMusic });

    await downloadFilesAsZip(audioFiles, imageFiles, videoFiles)
   
    await deductCredits()
    setBackToWaterMark(waterMarkedAsset);
    setIsDownloadingIndividual(false)
  };
  // download menu drop down which contains download as mp4 or downlaoad as gif
  const downloadMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="3"
        icon={<FileGifOutlined style={{ fontSize: "25px" }} />}
        onClick={handleDownloadGif}
      >
        Download as GIF
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={
          isMp4Downloding ? (
            <LoadingOutlined style={{ fontSize: "25px" }} />
          ) : (
            <CameraOutlined style={{ fontSize: "25px" }} />
          )
        }
        onClick={() => handleDownloadMp4(true)}
        disabled={isMp4Downloding}
      >
        Download as MP4
      </Menu.Item>
    </Menu>
  );

  // drop down for save menu
  const saveMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="1"
        icon={
          isUpdateLoading ? (
            <LoadingOutlined style={{ fontSize: "25px" }} />
          ) : (
            <FileImageOutlined style={{ fontSize: "25px" }} />
          )
        }
        onClick={() => {
          updateProject();
        }}
      >
        Update
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={
          isRenderAndSaving ? (
            <LoadingOutlined style={{ fontSize: "25px" }} />
          ) : (
            <FileImageOutlined style={{ fontSize: "25px" }} />
          )
        }
        onClick={() => {
          renderAndSave();
        }}
      >
        Render and Save
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={
          isSaveAsNewLoading ? (
            <LoadingOutlined style={{ fontSize: "25px" }} />
          ) : (
            <FileImageOutlined style={{ fontSize: "25px" }} />
          )
        }
        onClick={() => {
          createNewProject(userDetails);
        }}
      >
        Save as new
      </Menu.Item>

      <Menu.Item key={"3"} 
       icon={
        isDownloadingIndividual ? (
          <LoadingOutlined style={{ fontSize: "25px" }} />
        ) : (
          <FileImageOutlined style={{ fontSize: "25px" }} />
        )
      }
      onClick={handleDownloadAssets}>
        Extract Video & Audio
      </Menu.Item>
    </Menu>
  );

  const handleRederModalClose = () => {
    setRenderBegins(false);
    if (renderprogress >= 100 && downloadPercentage >= 100) {
      setRenderProgress(0);
      setDownloadPercentage(0);
    }
  };

  const downloadComplete = () => {
    setRenderBegins(false);
    setRenderProgress(0);
    setDownloadPercentage(0);
    // setBackToWaterMark(waterMarkedAsset);
    deductCredits();
  };
  return (
    <>
      <div className={"custom-action-container"}>
        {/* <AnimationDropdown store={store}/> */}
        <PreviewButton
          store={store}
          previewOpen={previewOpen}
          setPreviewOpen={setPreviewOpen}
        />
        {IS_FREE_PLATFORM === "false" && (
          <BulkCreateButton
            store={store}
            open={open}
            setOpen={setOpen}
            bulkCreatePropsArray={bulkCreatePropsArray}
            setBulkCreatePropsArray={setBulkCreatePropsArray}
          />
        )}
        <ScriptButton
          store={store}
          open={open}
          setOpen={setOpen}
          setProgressBarPercentage={setProgressBarPercentage}
          defaultActivetab="1"
          buttonName="Script"
        />
        {IS_FREE_PLATFORM === "false" && (
          <Dropdown
            overlay={saveMenu}
            trigger={["click"]}
            onVisibleChange={(flag) => setSaveMenuVisible(flag)}
            visible={saveMenuVisible}
          >
            <Button
              onClick={(e) => e.preventDefault()}
              icon={<SaveOutlined />}
              style={{
                background: "transparent",
                borderColor: "var(--color-primary-500)",
                marginBottom: "10px",
                color: "var(--color-primary-500)",
              }}
            >
              Save
            </Button>
          </Dropdown>
        )}
        {/* <Dropdown overlay={downloadMenu} trigger={['click']}> */}
        <Button
          // icon={<DownloadOutlined />}
          icon={isMp4Downloding ? <LoadingOutlined /> : <DownloadOutlined />}
          style={{
            background: "var(--gradient-primary)",
            borderColor: "transparent",
            color: "white",
            marginBottom: "10px",
          }}
          onClick={() => handleDownloadMp4(true)}
          // disabled={isMp4Downloding}
        >
          Download
        </Button>
        {/* </Dropdown> */}
        {creationSuccess && (
          <div
            style={{
              position: "fixed", // Or 'absolute'
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Overlay effect
              zIndex: 1000, // Ensure it's above other content
            }}
          >
            <div
              style={{
                padding: "20px",
                maxWidth: "600px",
                width: "90%",
                background: "#fff",
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Result
                status="success"
                title="Successfully Created New Project!"
                subTitle={`Project ID: ${newProjectID}. You can now navigate to the new project or stay on this page.`}
                extra={[
                  <Button
                    type="primary"
                    key="goNewProject"
                    onClick={handleGoToNewProject}
                  >
                    Go to New Project
                  </Button>,
                  <Button key="stay" onClick={handleStay}>
                    Stay on This Page
                  </Button>,
                ]}
              />
            </div>
          </div>
        )}
      </div>

      <Modal
        open={downloadModal}
        title="Rendering Video"
        footer={(_, { OkBtn, CancelBtn }) => <></>}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin />
        </div>
        <p>This will take a few minutes. </p>
        <p>Your download will start as soon as it is done.</p>
      </Modal>

      <Modal
        open={isdownloadLink}
        title="Download video"
        onOk={handleCloseDownload}
        onCancel={handleCloseDownload}
      >
        <p>Your video is ready to download</p>
        <a href={remotionDownloadUrl}>Click here to download</a>
        {/* <Spin/> */}
      </Modal>

      <Modal
        open={renderBegins}
        title="Video render"
        width={400}
        onCancel={handleRederModalClose}
        footer={(_, { OkBtn, CancelBtn }) => <></>}
      >
        {renderprogress >= 100 ? (
          <>
            {/* {downloadPercentage > 0 ? (
              <>
                {downloadPercentage >= 100 ? (
                  <p style={{ fontWeight: 'bold' }}>Video Downloaded</p>
                ) : (
                  <p style={{ fontWeight: 'bold' }}>Downloading video</p>
                )}
              </>
            ) : (
              <p style={{ fontWeight: 'bold' }}>
                Rendered successfully
              </p>
            )} */}
            {""}
          </>
        ) : (
          <>
            <p style={{ fontWeight: "bold" }}>
              Rendering your video in the background. This will take a few
              minutes. Your download will start as soon as it is done.
            </p>
          </>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: "3px",
            }}
          >
            <div>
              <p style={{ fontWeight: "bold" }}>Render status</p>
              <Progress
                type="circle"
                percent={renderprogress}
                strokeColor="#4096ff"
              />
            </div>
            {/* <div>
              <p style={{ fontWeight: 'bold' }}>Download status</p>
              <Progress
                type='circle'
                percent={downloadPercentage}
                strokeColor='#52c41a'
              />
            </div> */}
            {remotionDownloadUrl.length > 1 && (
              <a
                href={remotionDownloadUrl}
                style={{
                  marginTop: "10px",
                  display: "inline-block",
                  padding: "4px 15px",
                  background: "var(--gradient-primary)",
                  borderColor: "transparent",
                  color: "white",
                  marginBottom: "10px",
                  textDecoration: "none",
                  textAlign: "center",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  downloadComplete();
                }}
              >
                Download
              </a>
            )}
            <div></div>
          </div>
        </div>
        {/* {renderprogress >= 100 ? (
          <>
           {downloadPercentage > 0 ? ( <div style={{display:'flex', justifyContent:'center'}}>
            <span>Not downloading?<a style={{paddingLeft:'10px'}} href={remotionDownloadUrl}  onClick={ ()=>{
              setRenderBegins(false)
              setRenderProgress(0)
              setDownloadPercentage(0)
              setBackToWaterMark(waterMarkedAsset);
              cancelDownload()
            }}>Click here</a></span>
            </div>):''}
          </>
        ):''} */}
      </Modal>
    </>
  );
};
